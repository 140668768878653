<template>
  <div id="SignUpEnd">
    <div>
      <div class="header">
        <div v-if="status == 1" class="success"><van-icon name="success" /></div>
        <div v-if="status == 0" class="success red"><van-icon name="arrow-left" style="transform: rotate(-45deg);" /></div>
        {{status == 0? '抱歉，您还未报名成功':'恭喜您，报名成功！'}}
      </div>
      <div class="content">
        <div class="top">
          <p>{{dataDetail.title}}：{{dataDetail.city}}</p>
          <p>{{dataDetail.activityStart}}（{{getWeek($moment(dataDetail.activityStart).day())}}）</p>
          <p class="adress"><van-icon name="location" style="margin:0.02rem 0.05rem 0 0;font-size:0.14rem"/>{{dataDetail.city}}</p>
          <!-- <p class="first">定期邀请保险行业内的朋友分享自己的观点</p>
          <p class="first">政策&解读 | 互联网保险</p> -->
          <!-- <p class="second">¥899.00</p> -->
        </div>
        <div class="center">
          <span></span>
          <div></div>
          <span></span>
        </div>
        <div class="bottom">
          <p>{{type == 'c' ? '联系方式':'上课方式'}}</p>
          <p>{{type == 'c' ? '报名成功后工作人员会第一时间与您联系':'该课程为线上课程，订阅后工作人员会第一时间与您联系。'}} </p>
          <p>微信：18300702027<span class="copy" v-clipboard:copy="'18300702027'" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</span></p>
        </div>
      </div>
      <div class="botFixed">
        <span @click="toOther">再去逛逛</span>
        <span v-if="status == 0" @click="prepay" >去支付</span>
        <span v-else>分享好友</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SignUpEnd",
  data() {
    return {
      id:'',
      dataDetail:'',
      setInt:'',
      status:'',
      type:'',//c 线下活动
      payId:'',
      isPay:0
    };
  },
  mounted() {
    this.id = this.$route.query.activityId
    this.type = this.$route.query.type
    this.payId = this.$route.query.payId
    this.axios({
      method: 'GET',
      url: '/activity/checkUserApplyStatus/'+this.id,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.status = res.data.data.status
        this.dataDetail = res.data.data
        if(res.data.data.status == 0){
          this.forMore()
        }
      }
    })
  },
  methods: {
    toOther(){
      this.$router.push({
        path: 'Activity?activeName=c',
      })
    },
    forMore(){
      var _this = this;
      this.setInt = setInterval(function(){
        _this.axios({
          method: 'GET',
          url: '/activity/checkUserApplyStatus/'+_this.id,
          headers: {'Authorization': `Bearer ${_this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.status = res.data.data.status
            if(res.data.data.status == 1){
              this.dataDetail = res.data.data
              clearInterval(_this.setInt)
            }
          }
        })
      },5000)
    },
    prepay(){
      if( !this.isPay ){
        console.log('支付')
        this.isPay = 1
        this.axios({
          method: 'POST',
          url: '/payment/stayOrderPay',
          headers: {
            'Authorization': `Bearer ${this.Global.getCookie('token')}` 
          },
          data: {
            orderId: this.payId,
          }
        }).then( (res) => {
          if(res.data.code == 0){
            if(res.data.data.redirect){
              //免费
              this.$toast('购买成功！')
              // setTimeout(() => {
              //   this.$router.push('/SignUpEnd?type=c&activityId='+this.activityId)
              // }, 1000)
            }else{
              this.callPay(res.data.data)
            }
          }else{
            this.isPay = 0
            this.$toast(res.data.message)
          }
        })
      }
    },
    // 微信调起支付
    callPay(res){
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(res), false);
        }else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(res)); 
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(res));
        }
      }else{
        this.onBridgeReady(res);
      }
    },
    onBridgeReady(data){
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', data.config,
        (res) => {
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$toast('购买成功！')
            window.location.reload()
            // this.$router.push({
            //   path:'/Home'
            // })
          }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
            this.isPay = 0
          }else if(res.err_msg == "get_brand_wcpay_request:fail"){
            this.isPay = 0
            this.$toast('支付失败')
          }
      }); 
    },
    onCopy(){
      this.$toast('复制成功')
    },
    onError(){
      this.$toast('复制失败')
    },
    getWeek(a){
      switch(a){
        case 1:
        return '周一';
        case 2:
        return '周二';
        case 3:
        return '周三';
        case 4:
        return '周四';
        case 5:
        return '周五';
        case 6:
        return '周六';
        case 7:
        return '周日';
      }
    }
  },
  destroyed(){
    clearInterval(this.setInt)
  }
};
</script>
<style lang="less" scoped>
#SignUpEnd {
  .content{
    margin-bottom: 0.24rem;
    background: url(../../assets/image/findOut/back.png) no-repeat;
    background-size: 100% 100%;
    .center{
      height: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      >div{
        height: 0;
        border-bottom:1px dashed #ECEDF7;
        flex: 1;
        margin: 0 0.1rem;
      }
      >span{
        width: 0.2rem;
        height: 0.2rem;
        border-radius: 50%;
        background:#23252E;
      }
      >span:first-child{
        margin-left: -0.1rem;
      }
      >span:last-child{
        margin-right: -0.1rem;
      }
    }
    .bottom{
      padding: 0.05rem 0.18rem 0.18rem;
      >p{
        font-size: 0.12rem;
      }
      >p:first-child{
        color: #ECEDF7;
        margin-bottom: 0.08rem;
      }
      >p:nth-child(2){
        color: #989AB1;
        margin-bottom: 0.08rem;
      }
      >p:nth-child(3){
        color: #989AB1;
        >span{
          float: right;
          color: #F6F7FD;
        }
      }
    }
    .top{
      padding: 0.18rem 0.18rem 0.08rem;
      .adress{
        display: flex;
        align-items: center;
        color: #989AB1;
        font-size: 0.12rem;
      }
      .second{
        margin-top: 0.18rem;
        color: #F6F7FD;
        font-size: 0.21rem;
        font-weight: bold;
      }
      .first{
        color: #989AB1;
        font-size: 0.12rem;
        margin-bottom: 0.05rem;
      }
      >p:first-child{
        color: #F6F7FD;
        font-size: 0.15rem;
        margin-bottom: 0.06rem;
      }
      >p:nth-child(2){
        color: #ECEDF7;
        font-size: 0.13rem;
        margin-bottom: 0.06rem;
      }
    }
  }
  >div{
    background:#23252E;
    padding:0.28rem 0.15rem 0.24rem
  }
  .header{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F6F7FD;
    font-size: 0.15rem;
    margin-bottom: 0.2rem;
    .success{
      text-align: center;
      line-height: 0.21rem;
      background: linear-gradient(180deg, #7CFF9C 0%, #44D7B6 100%);
    }
    .red{
      background: linear-gradient(180deg, #FF636F 0%, #FE5E67 50%, #FC7277 100%);
    }
    >div{
      border-radius: 50%;
      width: 0.21rem;
      height: 0.21rem;
      margin-right: 0.1rem;
    }
  }
  .botFixed{
    display: flex;
    padding: 0 0.09rem;
    justify-content: space-between;
    box-sizing: border-box;
    >span{
      width: 1.5rem;
      height: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      font-size: 0.14rem;
      border-radius: 0.15rem;
      color: #3890FF;
      border:1px solid #3890FF;
    }
    >span:last-child{
      background: #3890FF;
      color: #fff;
    }
  }
}
</style>